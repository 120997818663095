@import "../base/variables";
@import "../base/mixins";

.kpi-weather--dashboard-wrapper,
.quote--dashboard-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 25px;
  margin-bottom: 30px;
}

.hero--dashboard {
  margin-bottom: -31px;
  margin-right: 40px;
  text-align: right;
}

.kpi-count-graph--dashboard-wrapper {
  display: grid;
  grid-gap: 15px 23px;

  .kpi-count {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 3fr;
    grid-gap: 23px;
  }

  .kpi-graph-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    grid-gap: 23px;
  }

  .kpi-graph {
    padding: 15px;
    border: 1px solid var(--grey-5);
    border-radius: 5px;
  }
}

.shortcut--dashboard {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 33px;
  margin-top: 35px;
}
