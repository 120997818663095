
$assetPath : "/assets/images";

.main-content {
  padding: 93px 33px 33px 33px;
  transition: transform .4s ease;
  transform: translateX(60px);
  width: calc(100% - 60px);

  &--reduce {
    transform: translateX(260px);
    width: calc(100% - 260px);
  }

  &--sidebar-info {
    display: grid;
    grid-template-columns: auto 387px;
    padding: 60px 0 0 0;

    .sidebar-info__main {
      padding: 34px;
    }

    .sidebar-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: calc(100vh - 60px);
      background: var(--subcolor-3-rgba);
      padding: 24px;

      .sidebar-info__text {
        font-size: em(20);
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        text-align: center;
        margin-bottom: 85px;
      }
    }
  }

  &--error {
    padding-top: 132px;
    padding-left: 66px;
    height: 100vh;
    // background-size: 65%;

    &--400 {
      background: url("../../images/error/error400.svg") no-repeat bottom right;
    }
    &--500 {
      background: url("../../images/error/error500.svg") no-repeat bottom right;
    }
  }
}

.container-error {
  width: 70%;
}

.block-error-top {
  margin-bottom: $spacer-xl / 2;
  h2 {
    font-weight: 500;
    font-size: 3.4em;
    margin-bottom: 38px;
  }
  p {
    font-size: 1.5em;
  }
  span {
    font-weight: 300;
    font-size: 1.7em;
  }
}

.block-error-bottom {
  font-weight: 300;
  font-size: 1.2em;
  li {
    display: flex;
    align-items: start;
    list-style: none;

    p {
      line-height: 26px;
      margin-bottom: 8px;
      margin-left: 10px;
    }
  }
}

.wrap {
  max-width: $wrapper-width + $spacer * 2;
  padding: 0 $spacer;
  margin: 0 auto;
}

.horizontal-container-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.horizontal-container-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
