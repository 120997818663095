@import "../base/variables";
@import "../base/mixins";

.partner-keys-figures {
  margin-bottom: 30px;

  &__title {
    font-size: em(18);
    font-style: normal;
    font-weight: normal;
    line-height: 32px;

    margin-bottom: 9px;
  }

  &__datas {
    display: grid;
    //grid-template-columns: 166px auto 1fr 1fr;
    grid-template-columns: 300px 1fr 1fr;
    grid-column-gap: 24px;

    .datas__first {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
    }

    .kpi-graph {
      padding: 15px;
      border: 1px solid var(--grey-5);
      border-radius: 5px;

      .title {
        margin-bottom: 15px;
      }
    }
  }
}
