@import "../base/variables";
@import "../base/mixins";

.header {
  height: $navbar-height;
  display: grid;
  grid-template-columns: 60px max-content auto 112px;
  color: var(--light);
  position: fixed;
  top: 0;
  z-index: z('header');
  width: 100%;

  background: var(--subcolor-1);

  &__burger {
    .menu {
      background-color: transparent;
      border: none;
      cursor: pointer;
      display: flex;
      padding: 0;
    }

    .line {
      fill: none;
      stroke: var(--light);
      stroke-width: 4;
      transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);

      &--first {
        stroke-dasharray: 60 207;
      }
      &--second {
        stroke-dasharray: 60 60;
      }
      &--third {
        stroke-dasharray: 60 207;
      }
    }

    &--opened {
      .line--first {
        stroke-dasharray: 90 207;
        stroke-dashoffset: -134;
      }
      .line--second {
        stroke-dasharray: 1 60;
        stroke-dashoffset: -30;
      }
      .line--third {
        stroke-dasharray: 90 207;
        stroke-dashoffset: -134;
      }
    }
  }

  &__intro {
    display: flex;
    align-items: center;
    margin-left: 13px;

    button {
      display: inline-block;
      background: var(--header-btn-intro-bg);
      border: 1px solid var(--header-btn-intro-border);
      border-radius: 20px;
      padding: 5px 10px;
      color: var(--light);

      font-family: Inter, serif;
      font-size: em(12);
      font-style: normal;
      font-weight: 400;
      line-height: 14px;

      span {
        margin-left: 7px;
      }
    }
  }

  &__utils {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    // justify-items: center;
    // justify-content: flex-end;
    border-right: 1px solid var(--grey-3);
    padding-right: 10px;

    .icon {
      padding: 0 18px;
      img {
        max-width: 25px;
      }
    }
  }

  &__account {
    display: grid;
    grid-template-columns: 56px 56px;
    align-items: center;
    justify-items: center;
    position: relative;

    .account__icon img {
      max-width: 37px;
    }

    .account__chevron {
      cursor: pointer;

      img {
        transition: transform .5s ease;
      }

      &--active {
        img {
          transform: rotate(180deg);
        }
      }
    }

    .account__submenu {
      position: absolute;
      top: $navbar-height;
      right: 2px;
      background: var(--light);
      border: 1px solid var(--grey-5);
      border-radius: 5px;
      color: var(--dark);
      margin-top: 5px;

      font-family: Inter, serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;

      .item {
        height: 40px;
        line-height: 40px;
        display: block;
        padding: 0 10px;

        &:not(:last-child) {
          border-bottom: 1px solid var(--grey-5);
        }
      }
    }
  }
}
