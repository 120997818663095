@import "../base/variables";
@import "../base/mixins";

.prospect-keys-figures {
  margin-bottom: 30px;

  &__title {
    font-size: em(18);
    font-style: normal;
    font-weight: normal;
    line-height: 32px;

    margin-bottom: 9px;
  }

  &__datas {
    display: grid;
    grid-template-columns: auto 1fr 1fr;
    grid-column-gap: 24px;

    .datas__first {
      display: grid;
      grid-gap: 10px;
    }

    .kpi-graph {
      padding: 15px;
      border: 1px solid var(--grey-5);
      border-radius: 5px;

      .title {
        margin-bottom: 15px;
      }
    }
  }
}

@import "../base/variables";
@import "../base/mixins";

.product-choice__wrapper {
  margin-top: 83px;
}

.prospect-choice {
  display: grid;
  grid-template-columns: repeat(2, 178px);
  justify-content: center;
  grid-gap: 40px;

  .button--choice {
    padding: 24px 0;
    align-items: center;
    line-height: 1.5;
  }
}

.table--communication {
  width: 70%;

  thead {
    height: 40px;

    th {
      text-align: left;
      width: 60px;
    }
  }
}

.table--documents {
  width: 90%;

  thead {
    height: 40px;

    th {
      text-align: left;
      width: 60px;

      &:nth-child(1) {
        width: 130px;
      }

      &:last-child {
        width: 100px;
      }
    }
  }

  tbody {
    button + button {
      margin-left: 10px;
    }
  }
}

.summary-rib-line {
  display: grid;
  grid-template-columns: repeat(7, max-content);
  grid-gap: 15px;
  align-items: center;
}

.summary-relation {
  display: grid;
  grid-template-columns: repeat(6, max-content);
  grid-gap: 15px;
  align-items: center;
}
