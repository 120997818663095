.sidebar {
  position: fixed;
  top: 0;
  z-index: z('highest');
  height: 100%;
  width: 100%;
  background: var(--light);
  transition: transform .4s ease;

  &--right {
    transform: translateX(100vw);
  }

  &--active,
  &--active-big {
    width: 90vw;
    transform: translateX(calc(100vw - 90vw));
  }

  &__content {
    height: calc(100vh - 80px);
    overflow-y: auto;
    padding: 30px;

    .title {
      font-size: em(22);
      font-weight: 600;
      margin-bottom: 20px;
    }
  }

  &__footer {
    position: fixed;
    bottom: 0;
    width: calc(100% - 32px);
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 2px solid var(--grey-5);
  }
}

@include min-screen($x-large) {
  .sidebar {
    &--active {
      width: 45vw;
      transform: translateX(calc(100vw - 45vw));
    }

    &--active-big {
      width: 75vw;
      transform: translateX(calc(100vw - 75vw));
    }
  }
}
