@import "../base/variables";
@import "../base/mixins";

.content {
  justify-content: center;
  align-items: center;
  padding: 5% 2.5%;

  &__image {
    display: flex;
    justify-content: center;

    img {
      display: flex;
      justify-content: center;
      align-items: center;
    }

  }

  &__text {
    margin: 10% 5% 5%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__specific-text {
    margin: 5%;
    display: flex;
    color: var(--error);
  }

  &__buttons {
    display: flex;
    justify-content: space-around;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 40px;
    cursor: pointer;
    background: none;
    border: none;
  }
}
