.badge-line {
  display: grid;
  grid-template-columns: 150px 150px auto;
  grid-gap: 1em;
  .tag-span {
    //grid-column-end: span 2;
    grid-column-start: span 2;
  }
  &--wide-columns {
    grid-template-columns: 180px 180px auto;
  }
  &--narrow-columns {
    grid-template-columns: 70px 70px auto;
  }
  &--space-when-empty {
    height: 60px;
  }
}

.badge-wrapper {
  &.status-span {
    grid-column-end: span 2;
  }
}

.badge {
  display: inline-block;
  font-size: em(11);
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  color: var(--light);
  position: relative;

  &.badge--shadow {
    box-shadow: 0 4px 4px var(--grey-shadow);
  }

  &.badge--min {
    .badge__text {
      padding: .5em 0.4em;
    }
  }

  &.badge--without-cta {
    .badge__container {
      grid-template-columns: max-content;
    }
  }

  &.status {

    &--info, &--alert {
      font-size: em(12);
      border-radius: 5px;
    }

    &--info {
      background-color: var(--grey-6);
      color: var(--ink-1);
      border: 1px solid var(--ink-1);
    }

    &--alert {
      background-color: var(--grey-6);
      color: var(--alert);
      border: 1px solid var(--alert);
    }
  }

  &.etat {
    font-weight: normal;
    border-radius: 2rem;
    color: var(--subcolor-2);
    background-color: var(--grey-6);
    border: 1px solid var(--grey-5);
    font-size: em(12);

    &--draft {
      background-color: var(--light);
      color: var(--subcolor-2);
      border-color: var(--subcolor-2);
      font-weight: 700;
    }

    &--engaged, &--old {
      background-color: var(--folder-light);
      color: var(--folder);
      border-color: var(--folder);
      font-weight: 700;
    }

    &--pending_confirmation, &--waiting {
      background-color: var(--verified-light);
      color: var(--verified);
      border-color: var(--verified);
      font-weight: 700;
    }

    &--invalid, &--excluded {
      background-color: var(--renvoi-light);
      color: var(--renvoi);
      border-color: var(--renvoi);
      font-weight: 700;
    }

    &--confirmed, &--signing, &--completed {
      background-color: var(--confirmed-light);
      color: var(--confirmed);
      border-color: var(--confirmed);
      font-weight: 700;
    }

    &--ordered {
      background-color: var(--renvoi-light);
      color: var(--renvoi);
      border-color: var(--renvoi);
      font-weight: 700;
    }

    &--subscribed, &--prospect {
      background-color: var(--validated-light);
      color: var(--validated);
      border-color: var(--validated);
      font-weight: 700;
    }

    &--validated, &--active, &--payment_validated {
      background-color: var(--ordered-light);
      color: var(--ordered);
      border-color: var(--ordered);
      font-weight: 700;
    }

    &--cancelled, &--blocked, &--deceased {
      background-color: var(--cancelled-light);
      color: var(--cancelled);
      border-color: var(--cancelled);
      font-weight: 700;
    }

    &--inactive {
      background-color: #E0E0E040;
      color: #1D1D1D;
      border-color: #1D1D1D;
      font-weight: 700;
    }

    &--reject, &--payment_rejected, &--refused {
      background-color: rgba(166, 17, 107, 0.25);
      color: #A6116B;
      border-color: #A6116B;
      font-weight: 700;
    }
  }

  &--partner-product {
    border: 1px solid #1D1D1D;
    border-radius: 20px;
    color: #1D1D1D;
    padding-left: 5px;
    padding-right: 5px;
  }
  &--partner-product-more {
    cursor: pointer;
    border: 1px solid #1D1D1D;
    border-radius: 20px;
    color: #1D1D1D;
    padding-left: 2px;
    padding-right: 2px;
  }

  .badge__container {
    display: grid;
    grid-template-columns: max-content auto;

    .badge__status{
      display: flex;

      img{
        margin-left: 8px;
      }
    }
  }

  .badge__text {
    display: inline-block;
    padding: 1em 0.8em;

    &.etat {
      padding: 0.8em 1.5em;
    }
    &.etat-small {
      font-size: em(15);
      padding: 0.4em 0.8em;
    }
    &.etat-small-xs {
      font-size: em(10);
      padding: 0.4em 0.8em;
    }
  }

  .badge__remove {
    background: none;
    border-color: transparent;
    cursor: pointer;
    outline: 0;

    display: inline-block;
    border-left: 1px solid var(--light);
    padding: 0 0.4em;
    color: var(--light);

    &.status {
      &--info, &--alert {
        border-left: none;
        color: var(--grey-3);
      }
    }
  }

  &:hover::after {
    content: attr(data-tooltip);
    display: none;
  }

  &:hover[data-tooltip]:not([data-tooltip=""])::after {
    display: block;
    position: absolute;
    bottom: 130%;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--tooltip-bg, #333);
    color: var(--tooltip-color, #fff);
    padding: 0.7em;
    border-radius: 0.25em;
    white-space: nowrap;
    font-size: 0.875em;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out;
  }

  &:hover[data-tooltip]:not([data-tooltip=""])::after {
    opacity: 1;
    visibility: visible;
  }
}

.badge-select:hover {
  box-shadow: 0 2px 2px var(--grey-shadow);
}

.reason {
  input {
    width: 170px;
  }
}
