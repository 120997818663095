@import "../base/variables";
@import "../base/mixins";

.quote {
  display: grid;
  grid-template-columns: 140px auto;
  align-items: center;
  padding: 30px 40px 30px 0;
  border: 1px solid var(--grey-5);
  border-radius: 4px;

  &__icon {
    text-align: center;
  }

  &__text {
    font-size: em(13);
    font-style: italic;
    align-self: center;
  }

  &__author {
    font-size: em(10);
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    text-align: right;
    margin-top: 5px;
  }
}
