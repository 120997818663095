.loader {
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__dot {
    width:12px;
    height:12px;
    background: currentColor;
    border-radius: 50%;
    box-shadow: 12px 0 currentColor,-24px 0 currentColor;
    animation:dot 1s infinite linear alternate;
  }
}

@keyframes dot {
  50% {box-shadow: 12px 0 currentColor,-12px 0 currentColor}
  100%{box-shadow: 24px 0 currentColor,-12px 0 currentColor}
}

.container--spinner {
  width: 100%;
  height: auto;
  min-height: 55vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 66px;
}
