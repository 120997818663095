@import "../base/variables";
@import "../base/mixins";

.product-keys-figures {
  margin-bottom: 30px;

  &__title {
    font-size: em(18);
    font-style: normal;
    font-weight: normal;
    line-height: 32px;

    margin-bottom: 9px;
  }

  &__datas {
    display: grid;
    grid-template-columns: 166px 2fr 1fr 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 24px;

    .datas__first {
      display: grid;
      grid-gap: 10px;
    }

    .kpi-graph {
      padding: 15px;
      border: 1px solid var(--grey-5);
      border-radius: 5px;

      .title {
        margin-bottom: 15px;
      }
    }
  }
}
.table--valorisation {
  width: 100%;

  thead {
    height: 40px;

    th {
      text-align: left;
    }
  }
}
.table--documentation {
  width: 500px;

  thead {
    height: 40px;

    th {
      text-align: left;
    }
  }
  tr td {
    input {
      width: auto;
    }
    button {
      margin-right: 15px;
    }
  }
}
.form--bloc--usage {
  .form-control:first-child {
    grid-template-columns: 250px auto;
  }
}
.form--bloc--distribution {
  .form-control {
    grid-template-columns: 170px auto;
  }
}
