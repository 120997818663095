@import "../base/variables";
@import "../base/mixins";

.bloc-information-count {
  $c: &;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border: 1px solid var(--grey-5);
  border-radius: 5px;

  font-family: Inter, sans-serif;
  font-style: normal;
  color: var(--subcolor-2);

  &__description {
    background: url('../../images/icons/mark.svg') no-repeat;
    background-size: cover;
    position: absolute;
    top: 5px;
    right: 5px;
    color: var(--grey-5);
    width: 15px;
    height: 15px;

    img {
      vertical-align: inherit!important;
    }
  }

  &__description:hover {
    background: url('../../images/icons/mark-blue.svg') no-repeat;
    background-size: cover;
  }

  &__number {
    font-weight: bold;
    font-size: em(24);
    padding-top: 10px;
  }

  &__text {
    font-size: em(18);
    line-height: 27px;
  }

  &--min {
    #{$c}__number {
      font-size: em(18);
    }

    #{$c}__text {
      font-size: em(14);
      padding: 1em;
    }
  }

  &--active {
    border: 2px solid var(--ink-1);
    box-sizing: border-box;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    color: var(--ink-1);
  }
}
