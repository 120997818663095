.product-choice__wrapper {
  margin-top: 83px;
}

.product-choice {
  display: grid;
  grid-template-columns: repeat(2, 178px);
  justify-content: center;
  grid-gap: 40px;

  .button--choice {
    padding: 24px 0;
    align-self: center;
  }
}
