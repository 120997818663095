@import "../base/variables";
@import "../base/mixins";

.alert {
  display: grid;
  grid-template-columns: max-content auto;
  align-items: center;
  border-radius: 4px;
  padding: 7px;

  &.alert-info {
    border: 1px solid var(--info);
  }

  .alert__text {
    font-size: em(12);
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    margin-left: 10px;
  }
}

.warning {
  display: grid;
  grid-template-columns: max-content auto;
  align-items: center;
  border-radius: 4px;
  padding: 7px;

  &.warning-info {
    border: 1px solid var(--error);
  }

  .warning__text {
    font-size: em(12);
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    margin-left: 10px;
  }
}

.form-error-info-dropdown {
  margin-bottom: 25px !important;
  ul {
    margin-top: $spacer-l / 2;
    display: flex;
    flex-wrap: wrap;
    padding: 0 40px;
    width: 100%;
  }
  li {
    font-size: 0.85714em;
    width: 50%;

  }
}

.form-error-info-dropdown > div {
  border: 1px solid var(--error);
  padding: 9px 15px;
  border-radius: 4px;
}

.form-error-info-dropdown--msg {
  span {
    margin-left: 5px;
  }
}

.form-error-info-dropdown--number {
  background-color: var(--error);
  color: var(--light);
  padding: 5px;
  border-radius: 15px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-right: auto;
  font-size: em(18)
}

.alert-new {
  padding: 1rem;

  &__text {
    margin: 0;
  }

  &.alert--sticky {
    position: sticky;
    left: 0; top: 60px;
    z-index: 9;
    width: 100%;
  }
  &.alert-error {
    border-left: 0.25rem solid var(--error);
    background-color: var(--error-light);
    color: var(--error);
  }
  &.alert-warning {
    border-left: 0.25rem solid var(--warning);
    background-color: var(--warning-light);
    color: var(--warning);
  }
  &.alert-info {
    border-left: 0.25rem solid var(--info);
    background-color: var(--info-light);
    color: var(--info);
  }
  &.alert-success {
    border-left: 0.25rem solid var(--success);
    background-color: var(--success-light);
    color: var(--success);
  }
}
