.form-label--input-radio {
  line-height: 1.1;
  display: grid;
  grid-template-columns: max-content auto;
  gap: 4px;

  font-size: em(12);
  font-style: normal;
  font-weight: 300;
}

input[type="radio"] {
  /* ChoiceType if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--light);
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 15px;
  height: 15px;
  border: 1px solid var(--dark);
  border-radius: 50%;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;

  &[readonly] {
    background-color: var(--grey-6);
  }
}

input[type="radio"]::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  /* Windows High Contrast Mode */
  background-color: var(--light);
  border-color: var(--light);
  box-shadow: 0 2px 4px 0 var(--grey-shadow);
}

input[type='radio']:checked {
  background: var(--ink-1);
  border: 0;
  box-shadow: 0 2px 4px 0 var(--grey-shadow);
  &[readonly] {
    opacity: .6 !important;
    background-color: var(--ink-1) !important;
  }
}

input[type="radio"]:checked::before {
  transform: scale(1);
  box-shadow: 0 2px 4px 0 var(--grey-shadow);
}
