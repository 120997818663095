.bloc-select {
  display: flex;
}

.bloc-select > label {
  display: flex;
}

.bloc-checkbox {
  display: grid;
  grid-template-columns: repeat(8,1fr);
}

.bloc-checkbox > .bloc-checkbox--visible {
  grid-column: 2;
  grid-row: 1;
}
.bloc-checkbox > .bloc-checkbox--create {
  grid-column: 5;
  grid-row: 1;
}
.bloc-checkbox > .bloc-checkbox--read {
  grid-column: 4;
  grid-row: 1;
}
.bloc-checkbox > .bloc-checkbox--export {
  grid-column: 7;
  grid-row: 1;
}
.bloc-checkbox > .bloc-checkbox--update {
  grid-column: 6;
  grid-row: 1;
}
.bloc-checkbox > .bloc-checkbox--list {
  grid-column: 3;
  grid-row: 1;
}
.bloc-checkbox > .bloc-checkbox--select-all {
  grid-column: 8;
  grid-row: 1;
}
