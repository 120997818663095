.process-bar-container {
    height: 8px;
    width: 100%;
    border-radius: 4px;

    .process-bar {
        height: 100%;
        border-radius: inherit;
        text-align: right;
    }
}
