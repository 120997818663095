.commissioning-choice__wrapper {
  margin: 4em 0 0 0;
}

.commissioning-choice {

  &--col-2 {
    justify-content: center;
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(2, 200px);

    .button--choice {
      padding: 24px 0;
      border: #0B8C6B dot-dash 2px;
      align-items: center;
      line-height: 1.5;
    }
  }

  &--col-3 {
    justify-content: center;
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(3, 200px);

    .button--choice {
      padding: 24px 0;
      border: #0B8C6B dot-dash 2px;
      align-items: center;
      line-height: 1.5;
    }
  }

}

.control-list {
  margin-top:30px;
  display: flex;

  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #1D1D1D;

    p {
      margin: 0;
    }

    &__child{
     p {
       font-style: normal;
       font-weight: 400;
       font-size: 12px;
       line-height: 18px;
       color: #1D1D1D;
       padding-left: 25px;
       margin: 0;
     }
    }
  }

  &__item {
    width: 100%;
    align-items: center;
    justify-content: space-between;

    div {
      width: 100%;
      &:nth-child(2) {
        text-align: right;
      }
      &:nth-child(3) {
        text-align: center;
      }
    }
  }

  button {
    color: #04668C;
    font-size: 11px;
    border-bottom: 1px solid #04668C;
    img {
      margin-right: 3px;
    }
  }
}

.control-list-process {
  margin-left: 12px;

  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
  }

  &__status {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }

  &__sidebar {
    &__title {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;
      display: flex;
      justify-content: space-between;
      gap: 15px;
    }

    &__status {
      font-style: normal;
      font-weight: 400;
      font-size: 9px;
      line-height: 18px;
      display: flex;
    }
  }
}
