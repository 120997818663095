.form-bloc--action {
  min-height: 210px;
}

.actions-item {
  display: grid;
  grid-template-columns: 133px auto;
  grid-gap: 20px;
  align-items: center;
}
