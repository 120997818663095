@import "../base/variables";

.sidebar-main-navigation {
  position: fixed;
  height: 100%;
  top: $navbar-height;
  background: var(--light);
  width: 60px;
  transition: width .4s ease;
  overflow: hidden;
  z-index: 3;

  border-right: 1px solid var(--grey-5);

  &--active {
    width: 260px;
  }

  &__version {
    position: absolute;
    left: 12px;
    bottom: 72px;
  }

  &__item {
    height: 47px;
    display: grid;
    grid-template-columns: 60px 200px;
    align-items: center;

    .item__icon {
      text-align: center;
    }

    &--active {
      color: var(--ink-2);
      border-left: 6px solid var(--ink-2);
      >div:nth-child(1){
        margin-left: -12px;
      }
      >div:nth-child(2){
        margin-left: -6px;
      }

      svg {
        path {
          fill: var(--ink-2);
        }
      }
    }
  }
}
