.checkbox-bool-container {
  display: flex;
  gap: 20px;
}

/* Style commun pour les cases "oui" et "non" */
.checkbox-bool {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  background-color: #F44336;

  &.checked {
    background-color: #4CAF50; /* Vert pour le "oui" */
  }
}
