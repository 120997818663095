@import "../base/variables";
@import "../base/mixins";

.swatch {
  margin: 5px;
  display: flex;
  cursor: pointer;
}

.color {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
  width: 20px;
  height: 20px;
  border-radius: 2px;
}

.popover {
  position: absolute;
  z-index: 2;
}

.cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
