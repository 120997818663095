.tag-box {
    display: flex;
    padding: 6px 6px;
    flex-wrap: wrap;
    border-radius: 0.3rem;
    border: 1px solid hsl(0, 0%, 80%);
    max-height: 20rem;
    overflow-y: auto;
}
.tag-box {
    .tag-item {
        background-color: hsl(0, 0%, 90%);
        border-radius: 2px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        margin: 2px;
        min-width: 0;
        box-sizing: border-box;
        .tag-title {
            border-radius: 2px;
            color: hsl(0, 0%, 20%);
            font-size: 85%;
            overflow: hidden;
            padding: 3px 6px;
            text-overflow: ellipsis;
            white-space: nowrap;
            box-sizing: border-box;
            img {
                margin-left: 3px;
            }
        }
    }
}
