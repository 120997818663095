@import "../base/variables";
@import "../base/mixins";

.bloc-information-shortcut {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  min-height: 70px;

  border: 1px solid var(--grey-5);
  border-radius: 5px;

  color: var(--subcolor-2);

  &:hover {
    border: 2px solid var(--ink-1);
    box-sizing: border-box;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    color: var(--ink-1);
  }

  &__text {
    font-size: em(14);
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0;
    margin-left: 10px;
  }
}
