.overlay {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: var(--grey-1);
  opacity: 0;
  z-index: -1;

  &--active {
    animation: animateOverlayFadeIn .5s forwards;
    z-index: z('menu');
  }

  &--close {
    animation: animateOverlayFadeOut .5s forwards;
    z-index: z('menu');
  }
}

@keyframes animateOverlayFadeIn {
  from {opacity: 0;}
  to {opacity: .9;}
}

@keyframes animateOverlayFadeOut {
  from {opacity: .9;}
  to {opacity: 0; z-index: -1;}
}
