@import "../base/variables";
@import "../base/mixins";

.tab {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;

  border-bottom: 1px solid var(--subcolor-2);

  &__item {
    padding: 0 25px;
    display: block;

    font-size: em(14);
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-align: center;

    &--active {
      position: relative;

      font-weight: 700;
      color: var(--subcolor-1);

      &:after {
        content: '';
        position: absolute;
        bottom: -2px; left: 0;
        width: 100%;
        height: 3px;
        background: var(--subcolor-1);
      }
    }
    &--disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
}
