@import "../base/variables";
@import "../base/mixins";

.uploader {
  height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--subcolor-3-rgba);
  cursor: pointer;

  &:hover {
    background: var(--subcolor-3);
  }
}
