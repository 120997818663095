.help {
  margin-top: 2px;
  font-size: em(10);
  color: var(--text-color);

  &-success {
    color: var(--success);
  }

  &-error {
    color: var(--error);
  }
}
