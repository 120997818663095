// Headings

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  line-height: 1.3;
  margin: 0 0 15px;
  font-family: $font-family-title;
}

h1, .h1 {
  font-size: em(round(56/2));
  font-weight: 700;
}

h2, .h2 {
  font-size: em(round(40/2));
  font-weight: 700;
}

h3, .h3 {
  font-size: em(round(32/2));
  font-weight: 700;
}

h4, .h4 {
  font-size: em(round(26/2));
  font-weight: 500;
}

h5, .h5 {
  font-size: em(round(22/2));
  font-weight: 600;
}

p, .p {
  font-size: $font-size-base-phone;
  line-height: em($line-height-base);
}

small, .small {
  font-size: em(12);
  line-height: em($line-height-small);
}

@include min-screen($desk) {
  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    margin: 0 0 $spacer;
  }

  h1, .h1 {
    font-size: em(56);
  }

  h2, .h2 {
    font-size: em(40);
    line-height: 46px;
  }

  h3, .h3 {
    font-size: em(32);
    line-height: 42px;
  }

  h4, .h4 {
    font-size: em(24);
  }

  h5, .h5 {
    font-size: em(20);
  }

  p, .p {
    font-size: $font-size-base;
    line-height: em($line-height-base);
  }
}
