//vars
$fg: #073359;
$bg: #8bc34a;
$pi: 3.14159265359;
$widthBubble: 10vw;
$heightBubble: 10vw;


//config
$menu-items: 9+1;
$open-distance: 21vw;
$opening-angle: $pi*2;

$_precision: 10;

@function pow($base, $exp) {
  $value: $base;
  @if $exp > 1 {
    @for $i from 2 through $exp {
      $value: $value * $base;
    }
  }
  @if $exp < 1 {
    @for $i from 0 through -$exp {
      $value: $value / $base;
    }
  }
  @return $value;
}

@function fact($num) {
  $fact: 1;
  @if $num > 0 {
    @for $i from 1 through $num {
      $fact: $fact * $i;
    }
  }
  @return $fact;
}

@function _to_unitless_rad($angle) {
  @if unit($angle) == "deg" {
    $angle: $angle / 180deg * $pi;
  }
  @if unit($angle) == "rad" {
    $angle: $angle / 1rad;
  }
  @return $angle;
}

@function sin($angle) {
  $a: _to_unitless_rad($angle);
  $sin: $a;
  @for $n from 1 through $_precision {
    $sin: $sin + (pow(-1, $n) / fact(2 * $n + 1) ) * pow($a, (2 * $n + 1));
  }
  @return $sin;
}

@function cos($angle) {
  $a: _to_unitless_rad($angle);
  $cos: 1;
  @for $n from 1 through $_precision {
    $cos: $cos + ( pow(-1,$n) / fact(2*$n) ) * pow($a,2*$n);
  }
  @return $cos;
}

@function tan($angle) {
  @return sin($angle) / cos($angle);
}

.container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  height: 100vh;
  width: 100%;
  font-family: Inter, sans-serif
}

.menu {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  font-size: 20px;
  text-align: left;
}

%bubble {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: $widthBubble;
  height: $heightBubble;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  background: radial-gradient(79.46% 127.05% at 34.06% 35.63%, #04668C 0%, #1F1B4E 100%);
  border-radius: 100%;
  color: white;
  text-align: center;

  span {
    margin-top: 8px;
    transition: all .3s ease-in;

    @media only screen and (max-width: 1080px) {
      display: none;
    }
  }

  &:hover span {
    text-shadow: -0.2px -0.2px 0 white, 0.2px -0.2px 0 white, -0.2px 0.2px 0 white, 0.2px 0.2px 0 white;
    letter-spacing: 1px;
  }

}

.menu__item {
  @extend %bubble;
  text-decoration: none;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -150%;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
    transform: skewX(-30deg);
    transition: .7s;
  }

  &:hover:before {
    left: 75%;
    background: rgba(255, 255, 255, 0.2);
  }

  i {
    width: $widthBubble / 3;
    height: $heightBubble / 3;
  }

  svg {
    width: $widthBubble / 3;
    height: $heightBubble / 3;
  }

}

.menu__item-dlg {
  @extend %bubble;
  z-index: 2;

  div {
    width: 80%;
    display: flex;
    align-items: end;

    i {
      display: flex;
      width: 34%;
      margin-right: -5px;
    }
  }

  svg {
    width: 75%;
    height: auto;
  }

  img {
    margin-top: 8px;
    width: 40%;
  }
}

.menu__item-dlg--is-active ~ .menu__item {

  @for $i from 1 through $menu-items {
    $angle: (($pi - $opening-angle)/1.63)+(($opening-angle/($menu-items - 1))*($i - 1));

    &:nth-child(#{$i+1}) {
      @if ($i == 1) {
        transform: translate3d(cos($angle)*$open-distance, sin($angle)*$open-distance * 0.9, 0);
      } @else if ($i == 2) {
        transform: translate3d(cos($angle)*$open-distance, sin($angle)*$open-distance * 0.9, 0);
      } @else if ($i == 3) {
        transform: translate3d(cos($angle)*$open-distance * 1.3, sin($angle)*$open-distance * 1.6, 0);
      } @else if ($i == 4) {
        transform: translate3d(cos($angle)*$open-distance * 0.9, sin($angle)*$open-distance * -0.9, 0);
      } @else if ($i == 5) {
        transform: translate3d(cos($angle)*$open-distance * 2, sin($angle)*$open-distance * 0.6, 0);
      } @else if ($i == 6) {
        transform: translate3d(cos($angle)*$open-distance * 90.7, sin($angle)*$open-distance * 0.7, 0);
      } @else if ($i == 7) {
        transform: translate3d(cos($angle)*$open-distance * 1.6, sin($angle)*$open-distance * 0.9, 0);
      } @else if ($i == 8) {
        transform: translate3d(cos($angle)*$open-distance * 1.4, sin($angle)*$open-distance * 1.6, 0);
      } @else if ($i == 9) {
        transform: translate3d(cos($angle)*$open-distance * 1.5, sin($angle)*$open-distance * 0.5, 0);
      } @else if ($i == 10) {
        transform: translate3d(cos($angle)*$open-distance * 0.7, sin($angle)*$open-distance * -0.9, 0);
      } @else {
        &:nth-child(#{$i+2}) {
          // transform: translate3d(cos($angle)*$open-distance, sin($angle)*$open-distance, 0);
        }
      }
    }
  }
}

.gooey-wrapper {
  position: absolute;
  top: 0;
}


