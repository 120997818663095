@import "../base/variables";
@import "../base/mixins";

.header-page {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 15px;
  margin-bottom: $spacer-m / 2;

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: em(24);
    line-height: 32px;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .item {
      margin-left: 20px;
      cursor: pointer;
    }
  }

  &__actions__container {
    margin-left: 46px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 15px;
  }
}
