.container-KPIs {
  display: flex;
}

.container-numbers {
  width: 30%;
  display: flex;
  flex-direction: column;
  height: 33vh;
  overflow: auto;
  margin-right: 40px;

  > div {
    margin-bottom: 10px;
    padding-right: 10px;
  }
  div:nth-last-child {
    margin-bottom: 0;
  }
}

.container-graph {
  width: 70%;
  height: 33vh;
  margin-right: 33px;
}

.container-canvas {
  height: 33vh;
  width: 100%;
}

.slick-next:before, .slick-prev:before {
  color: var(--ink-1) !important;
}