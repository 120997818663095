@import "../base/variables";
@import "../base/mixins";

.button {
  @extend %button-reset;
}

.button--shortcut {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 19px;

  color: var(--subcolor-3);
  font-style: normal;
  font-weight: normal;
  font-size: em(18);
  line-height: 27px;

  border: 1px solid var(--subcolor-3);
  border-radius: 4px;

  &:hover {
    color: var(--light);
    background: var(--subcolor-3);

    svg {
      path {
        fill: var(--light);
      }
    }
  }

  svg {
    margin-right: 14px;
  }
}

.button--add {
  display: flex;
  align-items: center;
  justify-content: center;

  font-style: normal;
  font-weight: bold;
  font-size: em(12);

  svg {
    margin-right: 14px;
  }

  &--subcolor3 {
    color: var(--subcolor-3);
  }
}

.button--submit {
  padding: 5px 26px;

  color: var(--light);
  font-style: normal;
  font-weight: bold;
  font-size: em(12);
  line-height: 16px;

  background: var(--ink-2);

  border-radius: 4px;
}

.button--white {
  padding: 5px 16px;

  color: var(--dark);
  font-style: normal;
  font-weight: 300;
  font-size: em(12);
  line-height: 16px;

  background: var(--light);

  border: 1px solid var(--subcolor-2);
  border-radius: 4px;
}

.button--ink-2 {
  padding: 10px 26px;

  color: var(--light);
  font-style: normal;
  font-weight: 400;
  font-size: em(12);
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: center;
  text-transform: uppercase;

  background: var(--ink-2);
  border-radius: 4px;

  &--outline {
    color: var(--ink-2);
    background: var(--light);
    border: 1px solid var(--ink-2);

    &:hover {
      color: var(--light);
      background: var(--ink-2);
    }
  }
}

.button--choice {
  padding: 10px 26px;
  min-height: 65px;

  color: var(--light);
  font-style: normal;
  font-weight: 400;
  font-size: em(12);
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: center;
  text-transform: uppercase;

  background: var(--ink-2);
  border-radius: 4px;

  &--deactivated {
    color: var(--grey-4);
    background: var(--light);
    border: 1px solid var(--grey-5);
    cursor: not-allowed;

    font-size: em(15);
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.01em;
    text-align: center;

  }

  &--outline {
    color: var(--subcolor-2);
    background: var(--light);
    border: 1px solid var(--subcolor-2);

    font-size: em(18);
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.01em;
    text-align: center;

    &:hover {
      color: var(--ink-2);
      border-color: var(--ink-2);
      font-weight: 700;
    }
  }
}

.button--error {
  padding: 8px 16px;

  color: var(--light);
  font-style: normal;
  font-weight: bold;
  font-size: em(12);
  line-height: 16px;

  background: var(--error);

  border: 1px solid var(--error);
  border-radius: 3px;
}

.button--valid {
  padding: 8px 16px;

  color: var(--light);
  font-style: normal;
  font-weight: bold;
  font-size: em(12);
  line-height: 16px;

  background: var(--success);

  border: 1px solid var(--success);
  border-radius: 3px;
}

.button--password-visibility {
  width: 25px;
  height: 25px;
  position: absolute;
  background-color: transparent;
  border: none;
  cursor: pointer;
  right: 0;
  padding: 0;
}

.button--disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.custom-button-dropdown {
  display: inline-block;
  position: relative;
}

.dd-button {
  display: inline-block;
  border: 1px solid gray;
  border-radius: 4px;
  padding: 5px 30px 3px 10px;
  background-color: #ffffff;
  cursor: pointer;
  white-space: nowrap;

  &:after {
    content: '';
    position: absolute;
    top: 15px;
    right: 15px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
  }

  &:hover {
    background-color: #eeeeee;
  }
}

.dd-input {
  display: none;
}

.dd-menu {
  top: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0;
  margin: 2px 0 0 0;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  list-style-type: none;
}

.dd-input + .dd-menu {
  display: none;
}

.dd-input:checked + .dd-menu {
  display: block;
  z-index: 9;
  width: 100%;
}

.dd-menu li {
  padding: 10px 20px;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    background-color: #f6f6f6;
  }

  a {
    display: block;
    margin: -10px -20px;
    padding: 10px 20px;
  }

  &.divider {
    padding: 0;
    border-bottom: 1px solid #cccccc;
  }
}
