.movement {
  .form--bloc--general-information,
  .form--bloc--action {
    height: 190px;
  }

  .agreement,
  .mutation {
    .form--bloc--general-information,
    .form--bloc--action {
      height: 230px;
    }
  }

  .form--bloc--action {
    .form-bloc__form {
      height: calc(100% - 18px - 22px);
    }

    .movement-actions {
      display: grid;
      grid-template-columns: 133px auto 30px;
      grid-gap: 20px;

      .movement-actions__date {
        align-self: center;
      }
    }
  }

  .form--bloc--particularity {
    .form-bloc__form {
      display: grid;
      grid-template-columns: repeat(4, auto);
      grid-gap: 10px;

      .form-control {
        grid-template-columns: 200px auto;
      }
    }
  }

  .form--bloc--recurrence {
    height: calc(100% - 26px);
  }

  .file-with-icon {
    cursor: pointer;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 5px;
    align-items: flex-start;

    span {
      font-family: Inter, serif;
      font-size: em(12);
      font-style: normal;
      font-weight: 300;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
      color: rgba(0, 0, 0, 0.56);
      text-decoration: underline;
    }
  }

  .table--investor,
  .table--movement,
  .table--list-documents {
    width: 100%;

    thead {
      height: 40px;
    }

    tbody tr td.have-ged {
      display: grid;
      grid-template-columns: max-content max-content;
      grid-gap: 10px;
      align-items: end;
    }
  }

  .table--list-documents {
    th:first-child {
      width: 200px;
    }

    td:not(:first-child) {
      text-align: center;
      button {
        padding: 3px;
      }
    }
  }

  .disabledContent {
    pointer-events: none;
    opacity: 0.4;
  }

  .form-control__documents {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;

    .form-control__documents__item {
      display: grid;
      grid-template-columns: 200px auto;
      grid-gap: 10px;
    }
  }

  .choices-shares {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 10px;
  }

  .remove-icon {
    width: 21px;
  }
}

.presubscription-keys .presubscription-keys__shortcut{
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  grid-gap: 23px;

  .datas__first {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;

    .bloc-information-count {
      min-height: 111px;
    }
  }
}

.movement-status--tag {
  padding-bottom: 2px;
}
