.chevrons {
    display: inline-block;
}
.chevron-bottom {
    width: 10px;
    height: 10px;
    bottom: -5px;
    position: relative;
    &--selected {
        width: 10px;
        height: 10px;
        margin-left: 10px;
      }
}

.chevron-top {
    width: 10px;
    height: 10px;
    right: -10px;
    top: -2px;
    position: relative;
    transform: rotate(180deg);
    &--selected {
        width: 10px;
        height: 10px;
        margin-left: 10px;
        transform: rotate(180deg);
    }
}
