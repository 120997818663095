@import "../base/variables";
@import "../base/mixins";

.table-fix-head {
  overflow: auto;
  max-height: 70vh;
}
.table-fix-head thead {
  position: sticky;
  top: -1px;
  background: white;
  z-index: 9;
  //box-shadow: inset 0 1px 0 var(--grey-5), inset 0 -1px 0 var(--grey-5);
}

.ceil-variant-light-blue {
  background-color: var(--col-ceil-table);
}
.ceil-variant-light-orange {
  background-color: var(--subcolor-3-rgba);
}
// autre façon d'avoir une bordure supérieure
//.table-fix-head thead:before {
//  content: '';
//  position: absolute;
//  top: 0;
//  width: 100%;
//  border: 1px solid var(--grey-5);
//}


.datalist {

  &__title {
    margin-bottom: 25px;
  }

  &__header {
    display: grid;
    grid-template-columns: auto 205px;
    margin-bottom: 14px;

    .filter {
      display: grid;
      grid-template-columns: auto max-content;
      align-items: start;

      &__input {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        > div {
          margin-right: 22px;
        }
      }

      &__actions {
        display: flex;
        flex-direction: row;

        > button {
          margin-right: 6px;
        }
      }
    }

    .header__right {
      display: grid;
      grid-template-columns: max-content max-content;
      grid-column-gap: 5px;
      justify-content: flex-end;
      align-items: flex-start;

      &--filter {
        display: grid;
        grid-template-columns: max-content max-content 18px;
        grid-column-gap: 5px;
        align-items: center;

        &__count {
          height: 18px;
          width: 18px;
          background-color: var(--subcolor-3);
          border-radius: 50%;
          display: inline-block;

          font-size: em(9);
          line-height: 18px;
        }
      }
    }
  }

  &__datas {

    .col-action {
      min-width: 70px;
    }

    .actions {
      width: max-content;
    }

    width: 100%;
    white-space: nowrap;
    border-collapse: collapse;

    thead th,
    tbody td {
      font-style: normal;
      font-weight: normal;
      font-size: em(12);
      line-height: 18px;
      text-align: left;

      &.align-right, &.align-td-right {
        text-align: right;
        display: table-cell;
      }

      &.align-center {
        text-align: center;
        display: table-cell;
      }
    }

    thead {
      tr {
        height: 30px;
        position: sticky;
      }

      th {
        font-style: normal;
        font-weight: bold;
        font-size: em(12);
        line-height: 18px;
        padding: 0 5px;

        &.sortable {
          cursor: pointer;
          padding-right: 25px;
        }

        &:first-child {
          padding-left: 10px;
        }

      }
    }

    tbody {
      tr {
        height: 30px;

        &:nth-child(odd) {
          background: var(--grey-5);
          border-left: 1px solid var(--grey-5);
          border-right: 1px solid var(--grey-5);
        }
        &:nth-child(even) {
          background: var(--grey-6);
        }

        &:hover {
          background: var(--hover-table);
          //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }

        &.line--error {
          background: var(--error);
          color: white;
        }

        &.line--warning {
          background: var(--warning);
          color: white;
        }
        &.line-disabled {
          background: var(--grey-3);
          color: var(--grey-6);
        }
        &.line-selected {
          background: var(--subcolor-3);
        }
      }
      td{
        padding: 0 5px;
         border: 1px solid white;
        &:first-child {
          padding-left: 5px;
          min-width: 85px; // pour la colonne action
        }

        button {
          margin-right: 10px;
        }
      }
    }
  }

  &__datas--dismemberment-ledger {
    table-layout: auto;

    td {
      max-width: 138px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.datalist__datas.datalist__datas--with-checkbox thead th:first-child {
  padding-left: 0;
  width: 30px;
}

.ceil-esn {
  width: max-content;
  img:nth-child(2){
    margin-left: $spacer-s / 2 !important;
  }
}

.table__investissement-information {
  border-collapse: inherit;
  tbody td {
    border: none;
  }
  tbody > tr {
    td:nth-child(1) {width: 85px;}

    td:nth-child(2) {
      width: 85px;
      display: flex;
      height: inherit;
      align-items: center;
    }

    td:nth-child(3) {width: 30px !important;}

    td:nth-child(4) {
      width: 130px;
      display: flex;
    }

    td:nth-child(5) {width: 120px;}

    td:nth-child(6) {width: 88px;}

    td:nth-child(7) {width: 70px;}

    td:nth-child(8) {width: 100px;}

    td:nth-child(9) {width: 100px;}

    td:nth-child(10) {
      width: 150px;
      display: flex;
      height: inherit;
      align-items: center;
    }

    td:nth-child(11) {width: 107px;}

    td:nth-child(12) {
      width: 150px;
      display: flex;
    }

    td:nth-child(13) {width: 138px;} // type mouvement

    td:nth-child(14) {width: 85px;} // date fin dém

    td:nth-child(15) { // clé dém
      width: 80px;
    }

    td:nth-child(16) {  // status
      width: 130px;
      display: flex;
      height: inherit;
      align-items: center;
    }

  }
}

.table__product_commission-shares-information {
  border-collapse: inherit;
  thead > tr {
    th {
      text-align: center;
    }
  }
  tbody > tr {
    td {
      text-align: center;
    }
  }
}

.table__ScaleDataList {
  border-collapse: inherit;
  min-width: 100%;
  width: max-content;
  tbody td {
    border: none;
  }
  tbody > tr {
    td:nth-child(1) {width: 255px;}

    td:nth-child(2) {width: 150px;}

    // td:nth-child(3) {width: 240px;}

    td:nth-child(4) {
      width: 85px;
    }
  }
}

.data_list-reset-border {
  border-collapse: inherit;
  tbody td {
    border: none;
  }
}
