@import "../base/variables";
@import "../base/mixins";

.table--document-tracking {
  width: 100%;

  thead {
    height: 40px;
  }

  .document-tracking__waiting-document {
    display: flex;
    justify-content: center;

    .form-control__input {
      margin: 0 10px;
    }
  }
}
