.table--choice-investor-receiving-shares {
  // background-color: lightblue;
  .thead .tr:first-child {
    // background-color: lightcoral;
    display: grid;
    grid-gap: 25px;
    grid-template-columns: 70px 300px 310px 300px 310px 70px;

    button {
      margin-bottom: 0 !important;
    }
  }
  .thead .tr:last-child {
    // background-color: lightgreen;
    display: grid;
    grid-gap: 25px;
    grid-template-columns: 70px 70px 205px 80px 205px 70px 205px 80px 205px 70px;
    margin: $spacer-m / 2 0;

    th {
      text-align: left;
    }
    th:last-child {
      text-align: center !important;
    }
  }

  .tbody .tr{
    // background-color: lightsalmon;
    display: grid;
    grid-gap: 25px;
    grid-template-columns: 70px 70px 205px 80px 205px 70px 205px 80px 205px 70px;
    margin-bottom: $spacer / 2;
  }
}

.input--small {
  width: 70px;
}

.input--large {
  width: 205px;
}