.no-border input {
  border: none;
  border-bottom: 1px solid var(--grey-5);
  color: var(--input-color);
}

.input-no-border {
  margin-bottom: $spacer-s;

  svg {
    margin-right: 9px;
  }

  input {
    border: none;
    border-bottom: 1px solid var(--grey-5);
    color: var(--input-color);

    &[name="keyword"] {
      min-width: 250px;
    }
  }
}

.min-height-input {
  input {
    min-height: 38px;
  }
}

.initial-grid {
  .form-control {
    display: block;

    .form-control__input {
      padding: 5px 150px;
    }
  }
}

.form-bloc, .form-bloc-scale {
  border: 1px solid var(--grey-5);
  border-radius: 4px;
  padding: 9px 15px;
  margin-bottom: 12px;

  &__title {
    margin-bottom: 22px;

    font-size: em(14);
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
  }

  &__form {
    .table--list-documents {
      width: 100%;
    }

    .form-resume {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px;

      border: 1px solid var(--grey-5);
      border-radius: 4px;

      &__expend {
        @extend %button-reset;
        background: transparent;
        border: none;
        transition: transform .3s linear;
      }

      &--actif {
        .form-resume__expend {
          transform: rotate(180deg);
        }
      }

    }

    &--shortcut {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
    }
  }

  &__footer {
    position: sticky;
    bottom: 0;

    background: var(--light);
    border-top: 2px solid var(--grey-5);
    text-align: center;

    button {
      margin: 15px 13px;
    }
  }
}


.vertical-expending {
  overflow: hidden;
  max-height: 0;
  width: 100%;
  transition: max-height 1.15s ease-out;

  &--active {
    max-height: 100%;
    overflow: visible;
    transition: max-height 1.15s ease-in;
  }
}

.sidebar .form-bloc {
  border: none;
  border-radius: 0;
  padding: 0;
  margin-bottom: 0;
}

.form-control {
  $c: &;

  display: grid;
  grid-template-columns: 140px auto;
  align-items: baseline;
  grid-column-gap: 1em;

  &__errors {
    margin-left: calc(141px + 1em);
    color: var(--error);
    font-size: 0.9em;
    font-weight: lighter;
  }

  &--center {
    align-items: center;
  }

  &--wide-columns {
    grid-template-columns: 170px 170px auto;
  }

  &--textarea {
    align-items: flex-start;

    #{$c}__label {
      padding-top: 5px;
    }
  }

  &--label-auto {
    display: flex;
    flex-direction: row;
  }

  &--label-max-content {
    grid-template-columns: max-content auto;
  }

  &__label {
    font-size: em(12);
    font-style: normal;
    font-weight: 300;
    line-height: 18px;

    .required {
      font-weight: 600;
      color: var(--error);
    }
  }

  &__max-content {
    width: max-content;
  }

  &__input {
    input {
      width: 100%;
      height: 25px;
      border: 1px solid var(--grey-5);
      color: var(--input-color);

      font-size: em(12);
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      background: var(--light);
    }

    textarea {
      width: 100%;
      border: 1px solid var(--grey-5);

      font-size: em(12);
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      background: var(--light);
    }

    select {
      width: 100%;
      height: 25px;
      border: 1px solid var(--grey-5);

      font-size: em(12);
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      background: var(--light);
    }

    .information {
      margin-top: 5px;

      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      &--info {
        color: var(--info);
      }

      &--success {
        color: var(--success);
      }

      &--error {
        color: var(--error);
      }

      &--warning {
        color: var(--warning);
      }
    }

    input,
    select,
    textarea {
      &:disabled {
        background: var(--grey-6);
        border-color: var(--grey-6);
      }
    }

    input:not([type="radio"]),
    textarea {
      &:disabled,
      &:read-only {
        background: var(--grey-6);
        border-color: var(--grey-6);
      }
    }
  }

  &__readonly {
    background: transparent !important;
    opacity: 1 !important;
    color: var(--body-color) !important;
  }
}

input[type="checkbox"] {
  width: 15px;
  height: 15px;

  &:disabled {
    cursor: not-allowed;
    opacity: .5;
  }
}

input[type="checkbox"]:checked {
  -webkit-box-shadow: 0 2px 4px 0 #00000040;
  -moz-box-shadow: 0 2px 4px 0 #00000040;
  box-shadow: 0 2px 4px 0 #00000040;
  accent-color: var(--ink-1);
  width: 15px;
  height: 15px;
}

.multi-criteria:before {
  content: "Unique" !important;
  transform: translate(-65%, -50%) !important;
}

.checkbox-toggle:checked + .multi-criteria:before {
  content: "Multi" !important;
  transform: translate(-35%, -50%) !important;
}

.checkbox-toggle:checked + .multi-criteria:after {
  left: 18% !important;
}

.checkbox-toggle + .multi-criteria:after {
  left: 83% !important;
}

.checkbox-toggle + .multi-criteria {
  min-width: 80px !important;
  background-color: var(--ink-1) !important;
}

.checkbox-toggle {
  display: none;

  // add default box-sizing for this scope
  &,
  &:after,
  &:before,
  & *,
  & *:after,
  & *:before,
  & + .checkbox-toggle__button {
    box-sizing: border-box;

    &::selection {
      background: none;
    }
  }

  + .checkbox-toggle__button {
    outline: 0;
    display: block;
    height: 24px;
    position: relative;
    cursor: pointer;
    user-select: none;
    min-width: 65px;

    &:after, {
      position: relative;
      display: block;
      width: 29px;
      height: 29px;
      content: "";
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5), inset 0 1px 1px rgba(255, 255, 255, 0.1);
    }

    &:after {
      left: 20%;
    }

    &:before {
      width: 100%;
      display: flex;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-30%, -50%);
      justify-content: center;
      content: "non";
      color: var(--light);;
      font-size: $font-size-root;
      text-transform: capitalize;
    }
  }

  &:checked + .checkbox-toggle__button:before {
    content: "oui";
    transform: translate(-70%, -50%);
  }

  &:checked + .checkbox-toggle__button:after {
    left: 80%;
  }

  &:disabled + .checkbox-toggle__button {
    opacity: 0.2;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    //height: 15px;
    //width: 15px;
  }

  &__label {
    padding-left: 11px;
    font-family: $font-family-base;
    color: var(--grey-6);

    &--bold {
      font-size: em(14);
      font-weight: 700;
      color: var(--text-dark);
    }

    &--checked {
      color: var(--grey-5);
    }
  }

  &__readonly {
    opacity: 1 !important;
  }
}

.checkbox-toggle--light {
  + .checkbox-toggle__button {
    background: var(--grey-4);
    border-radius: 2em;
    padding: 2px;
    transition: all .4s ease;
    box-shadow: $box-shadow-inset;

    &:after {
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      background: var(--light);
      transition: all .2s ease;
    }
  }

  &:checked + .checkbox-toggle__button {
    background: var(--ink-1);

    &:after {
      border-radius: 50%;
      background: var(--light);
      transition: all .2s ease;
    }
  }
}

.checkbox-toggle--small {
  + .checkbox-toggle__button {
    background: var(--grey-4);
    border-radius: 2em;
    padding: 2px;
    transition: all .4s ease;
    box-shadow: $box-shadow-inset;

    &:after {
      position: absolute;
      top: 50%;
      width: 18px;
      height: 18px;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      background: var(--light);
      transition: all .2s ease;
    }
  }

  &:checked + .checkbox-toggle__button {
    background: var(--ink-1);

    &:after {
      border-radius: 50%;
      background: var(--light);
      transition: all .2s ease;
    }
  }
}

.mark-tooltip {
  height: 13px;
  margin-left: 5px;
  margin-bottom: 5px;
}

.grip-columns-xs {
  .form-control {
    grid-template-columns: 30px auto;
  }
}

.multiline-tooltip {
  display: flex;
  flex-direction: column;
}