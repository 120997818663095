.completeness-of-file {
  display: grid;
  grid-template-columns: max-content auto;
  grid-column-gap: 10px;
  align-items: baseline;
  margin-bottom: 30px;

  &__status {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
  }
}
