.u-pointer {
  cursor: pointer;
}

.disabled-content {
  pointer-events: none;
  opacity: 0.4;
}

.u-align-baseline {
  display: flex;
  align-items: baseline;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}


.w-full {
  width: 100%;
}

.w-max {
  width: max-content;
}

.w-min {
  width: min-content;
}

.w-fit {
  width: fit-content;
}

.display-none {
  display: none;
}

.opacity-10 {
  opacity: 0.10;
}
.opacity-20 {
  opacity: 0.20;
}
.opacity-30 {
  opacity: 0.30;
}
.opacity-40 {
  opacity: 0.40;
}

.mandatory-field {
  &:before {
    color: #A6440C ;
    font-weight: bolder;
    margin-left: -10px;
    content: '*';
    font-size: 15px;
    position: absolute;
  }
}

.mandatory-scroll-field {
  &:before {
    color: #A6440C ;
    font-weight: bolder;
    margin-left: -10px;
    content: '*';
    font-size: 15px;
    padding-right: 0.1rem;
  }
}

.field-in-error {
  border-bottom: 1px solid var(--error) !important;
}

.field-in-success {
  border-bottom: 1px solid var(--success) !important;
}

.mt-2 {
  margin-top: 2em
}
