@import "../base/variables";
@import "../base/mixins";

.collapse {
  &__title {
    @extend %button-reset;

    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--grey-5);

    font-size: em(12);
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;

    img {
      transition: transform .5s ease;
    }

    &--active {
      > img {
        transform: rotate(180deg);
      }

      & + .collapse__content {
        max-height: 123px;
        transition: max-height 0.25s ease-in;
      }

      & + .collapse__content-full {
        max-height: 100%;
        transition: max-height 0.25s ease-in;
      }
    }
  }

  &__title-full {
    @extend .collapse__title;
    border-bottom: none;
  }

  &__content {
    max-height: 0;
    transition: max-height .5s ease-out;
    overflow: hidden;
  }

  &__content-full {
    max-height: 0;
    transition: max-height ease-out;
    overflow: hidden;
  }
}

details[open] .arrow {
  transform: rotate(180deg);
}

.collapse-native {
  border: 1px solid var(--grey-5);
  border-radius: 10px;
  padding: 5px 0 !important;
  summary {
    display: flex;
    padding: 0 10px;
    align-items: center;
    list-style: none;
    user-select: none;
    font-size: em(12);
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    border-bottom: 1px solid transparent;
    cursor: pointer;
  }

  .arrow {
    transition: transform 0.4s ease-in-out;
  }

  .remove {
    margin-bottom: 4px;
  }

  &--object {
    padding-left: $spacer-m;
    font-weight: bold;
  }

  &--content {
    border-top: 1px solid var(--grey-5);
    padding: 10px;
    > div {
      margin-top: $gutter-width / 1.5;
    }
  }
}
