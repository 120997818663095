@import "../base/variables";
@import "../base/mixins";

.pagination {
  margin-top: 39px;
  height: 36px;
  display: flex;
  justify-content: center;
  @extend %list-reset;

  &__prev,
  &__item,
  &__next {
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--grey-4);
  }

  &__prev,
  &__next {
    &--disabled {
      cursor: not-allowed;

      svg {
        path {
          fill: var(--grey-4);
        }
      }
    }
  }

  &__item {
    &--active {
      color: var(--subcolor-2);
      position: relative;

      &:before {
        content: '';
        position: absolute;
        bottom: 7px;
        left: 50%;
        transform: translateX(-50%);

        height: 1px;
        width: 10px;
        background: var(--subcolor-2);
      }
    }

    &:hover {
      color: var(--subcolor-2);
    }
  }
}
