
.container-object {
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px 17px 3px;
  background-color: #f8f8f8;
  border-left: 1px solid #d1d1d1;
  border-right: 1px solid #d1d1d1;
  border-top: 1px solid #d1d1d1;

  .block-object {
    position: relative;
    flex: 1 1;
    margin: 0 $spacer;

    input {
      border-radius: 4px;
      padding: 5px 8px;
    }

    span {
      position: absolute;
      display: flex;
      right: -8px;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .info-author {
    color: #B1B1B1;
  }
}

.cke_chrome {
  border-top: none !important;
}